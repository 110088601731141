import { mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'
import _find from 'lodash/find'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { isMobile } from '@platform-shared/utils/device'

import {
  QUESTIONNAIRE_TYPES,
  QUESTIONNAIRE_STATUS,
  HEALTH_ACTION_CODES,
} from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('healthActions', [
      'completedHealthActions',
      'healthActionById',
    ]),
    ...mapGetters('questionnaire', ['questionnaireByType', 'isLoading']),
    mockCahps() {
      return this.questionnaireByType(this.questionaireType)
    },
    mockCahpsStatus() {
      return _get(this.mockCahps, 'status') || QUESTIONNAIRE_STATUS.NOT_STARTED
    },
    mockCahpsStarted() {
      return this.mockCahpsStatus === QUESTIONNAIRE_STATUS.STARTED
    },
    mockCahpsHasPreviousCompletion() {
      return _get(this.mockCahps, 'allCompletions', []).length > 0
    },
    mockCahpsLastTaken() {
      const date = _get(this.mockCahps, 'lastCompleted')
      return date ? format(parseISO(date), 'MM/dd/yyyy') : date
    },
    mockCahpsIsComplete() {
      return !!_find(this.completedHealthActions, {
        code: this.healthActionCode,
      })
    },
    healthActionId() {
      return isMobile ? _get(this.action, 'id') : this.$route.params.id
    },
    healthAction() {
      return this.healthActionById(this.healthActionId)
    },
    healthActionCode() {
      return _get(this.healthAction, 'code')
    },
    questionaireType() {
      switch (this.healthActionCode) {
        case HEALTH_ACTION_CODES.HEALTH_PLAN_FEEDBACK_SURVEY:
          return QUESTIONNAIRE_TYPES.MOCK_CAHPS
        case HEALTH_ACTION_CODES.MCD_ADULT_HEALTH_PLAN_FEEDBACK_SURVEY:
          return QUESTIONNAIRE_TYPES.MEDICAID_ADULT_MOCK_CAHPS
        case HEALTH_ACTION_CODES.MCD_YOUTH_HEALTH_PLAN_FEEDBACK_SURVEY:
          return QUESTIONNAIRE_TYPES.MEDICAID_CHILD_MOCK_CAHPS
      }
    },
    mockCahpsQuestionnaireUrl() {
      return `/questionnaire/${this.questionaireType}`
    },
  },
  methods: {
    ...mapActions('questionnaire', ['getQuestionnairesByType']),
  },
  mounted() {
    if (!this.mockCahps && !this.isLoading) {
      this.getQuestionnairesByType(this.questionaireType)
    }
  },
}
