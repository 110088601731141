import { mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import head from 'lodash/fp/head'
import getOr from 'lodash/fp/getOr'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import format from 'date-fns/format'
import isValidDate from 'date-fns/isValid'
import maxDate from 'date-fns/max'
import parseISO from 'date-fns/parseISO'
import {
  HEALTH_ACTION_CODES,
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_TYPES,
  FEATURES,
} from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('questionnaire', ['questionnaireByType', 'isLoading']),
    ...mapGetters('healthActions', ['healthActionByCode']),
    ...mapGetters('healthProfile', ['overallRisk']),
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('member', ['memberCoverageInfo']),
    riskBubbleClass() {
      return _get(this.overallRisk, 'color', '') === 'danger'
        ? 'text-red-500'
        : 'text-tan-500'
    },
    riskColor() {
      const riskLevel = _get(this.overallRisk, 'color')
      const colorMap = {
        danger: 'red',
        warning: 'tan',
      }
      return _get(colorMap, riskLevel)
    },
    hraHealthAction() {
      return this.healthActionByCode(
        HEALTH_ACTION_CODES.COMPLETE_HEALTH_QUESTIONNAIRE
      )
    },
    healthActionLastCompletedDate() {
      return flow(
        getOr([], 'completedMembers'),
        find({ memberId: this.memberCoverageInfo.memberId }),
        getOr([], 'completions'),
        orderBy(['completionDate'], ['desc']),
        map(({ completionDate }) => parseISO(completionDate)),
        filter(isValidDate),
        head
      )(this.hraHealthAction)
    },
    questionnaireLastCompletedDate() {
      return flow(getOr(null, 'lastCompleted'), (date) =>
        date ? parseISO(date) : null
      )(this.hra)
    },
    hra() {
      return this.questionnaireByType(QUESTIONNAIRE_TYPES.HRA)
    },
    hraSurvey() {
      return this.questionnaireByType(QUESTIONNAIRE_TYPES.HRA_SAT)
    },
    hraStatus() {
      return _get(this.hra, 'status') || QUESTIONNAIRE_STATUS.NOT_STARTED
    },
    hraNotStarted() {
      return this.hraStatus === QUESTIONNAIRE_STATUS.NOT_STARTED
    },
    hraStarted() {
      return this.hraStatus === QUESTIONNAIRE_STATUS.STARTED
    },
    hraCompleted() {
      return this.hraStatus === QUESTIONNAIRE_STATUS.COMPLETED
    },
    hraHasPreviousCompletion() {
      return (
        _get(this.hra, 'allCompletions', []).length > 0 ||
        !!this.healthActionLastCompletedDate
      )
    },
    hraIsCompliant() {
      return !!this.hraHealthAction.completedMembers.length
    },
    hasHraRisk() {
      return this.hasFeature(FEATURES.HRA_RISK)
    },
    hraOverallRisk() {
      return _isEmpty(this.overallRisk) ? null : this.overallRisk
    },
    hraLastTaken() {
      const lastCompletionDate = flow(
        filter(isValidDate),
        maxDate
      )([
        this.healthActionLastCompletedDate,
        this.questionnaireLastCompletedDate,
      ])

      return format(lastCompletionDate, 'MM/dd/yyyy')
    },
    showHraDisclaimer() {
      return !this.hraIsCompliant && !this.hraStarted
    },
    canRefuse() {
      return !!_get(this.hra, 'canRefuse')
    },
  },
  methods: {
    ...mapActions('questionnaire', ['getQuestionnairesByType', 'start']),
    // for mobile
    startHra() {
      this.start(this.hra).then(() => {
        this.goToHra()
      })
    },
    continueHra() {
      this.goToHra()
    },
    // for mobile
    takeSurvey() {
      if (_get(this.hraSurvey, 'status') !== QUESTIONNAIRE_STATUS.STARTED)
        this.start(this.hraSurvey).then(() => {
          this.goToHraSat()
        })
      else this.goToHraSat()
    },
    // for mobile
    goToHraSat() {
      this.$router.push('questionnaire', {
        props: {
          questionnaire: this.hraSurvey,
        },
      })
    },
    // for mobile
    goToHra() {
      this.$router.push('questionnaire', {
        props: {
          questionnaire: this.hra,
        },
      })
    },
  },
  mounted() {
    if (!this.hra && !this.isLoading) {
      this.getQuestionnairesByType(QUESTIONNAIRE_TYPES.HRA)
      this.getQuestionnairesByType(QUESTIONNAIRE_TYPES.HRA_SAT)
    }
  },
}
