<template>
  <div>
    <FormHeader @onCancel="handleCancel" />
    <div class="container flex-grow flex flex-col items-center p-12">
      <div class="w-full max-w-3xl py-20 bg-white mb-100">
        <slot></slot>
        <FormFooter
            :backText="backText"
            :completeText="completeText"
            @onComplete="handleComplete"
            @onCancel="handleCancel"
        />
      </div>
    </div>
  </div>
</template>
  
<script>
  import FormFooter from '../../../../common/forms/FormFooter.vue'
  import FormHeader from '../../../../common/forms/FormHeader.vue'
  
  export default {
    components: { FormFooter, FormHeader },
    props: {
      handleComplete: {
        type: Function,
        required: true
      },
      handleCancel: {
        type: Function,
        required: true
      },
      completeText: {
        type: String,
        default: "health_actions.member_activity_submission.wrapper_complete_btn"
      },
      backText: {
        type: String,
        default: "health_actions.member_activity_submission.wrapper_cancel_btn"
      },
    },
    computed: {
    },
    methods: {
    },
  }
</script>
  