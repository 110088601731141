import { mapGetters, mapActions } from 'vuex'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { MEMBER_ACTIVITY_SUBMISSION_TYPES } from '@platform-shared/constants'
import { isWithinDateInterval } from '@platform-shared/utils/dateHelpers'
import healthActionMixin from './healthActionMixin.js'

export default {
  created() {
    if (this.isLearnAndEarn) {
      this.initLearn()
    }
  },
  mixins: [healthActionMixin],
  computed: {
    ...mapGetters('member', ['memberCoverageInfo', 'memberInfo']),
    healthActionSpecificMember() {
      return _find(this.assignedMembers, {
        memberId: this.memberCoverageInfo.memberId,
      })
    },
    hasSubmittedProfileUpdate() {
      return this.healthActionSpecificMember.profileUpdated
    },
    hasMarkedArticleAsRead() {
      return _get(
        this.healthActionSpecificMember,
        'articlesMarkedAsRead',
        []
      ).includes(this.healthAction.parameter.contentId)
    },
  },
  methods: {
    ...mapActions('healthActions', [
      'markArticleAsRead',
      'submitProfileUpdate',
    ]),
    memberSubmittedActivityThisPeriod(
      activityType,
      additionalCondition = () => true
    ) {
      const { startDate, endDate } = this.currentCompliancePeriod

      const activities = this.getActivityByType(activityType)

      for (const activity of activities) {
        if (additionalCondition(activity)) {
          const activityDate = new Date(activity.activityTs)
          if (
            isWithinDateInterval(activityDate, {
              start: startDate,
              end: endDate,
            })
          ) {
            return true
          }
        }
      }
      return false
    },

    memberUpdatedProfileThisPeriod() {
      return this.memberSubmittedActivityThisPeriod(
        MEMBER_ACTIVITY_SUBMISSION_TYPES.PROFILE_UPDATE
      )
    },

    memberMarkedArticleAsReadThisPeriod() {
      return this.memberSubmittedActivityThisPeriod(
        MEMBER_ACTIVITY_SUBMISSION_TYPES.READ_ARTICLE,
        (activity) => {
          return (
            activity.activityPayload.contentId ===
            this.healthAction.parameter.contentId
          )
        }
      )
    },

    handleLearnAndEarnComplete() {
      return this.markArticleAsRead({
        healthActionCode: this.healthAction.code,
        memberId: this.memberInfo.id,
        contentId: this.healthAction.parameter.contentId,
      })
    },

    handleProfileUpdateComplete() {
      this.submitProfileUpdate({ memberId: this.memberInfo.id }).then(() =>
        this.$router.back()
      )
    },
  },
}
